import { Link } from 'gatsby'
import React from 'react'
import { siteMetadata } from '../../gatsby-config.js'

import Meta from 'components/meta'
import Layout from 'components/layout'

const ContactThanks = ({ location }) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} />
      <div className="content-wrapper">
        <div className="main-wrapper clearfix">
          <div className="mosh-breadcumb-area">
            <div className="container h-100">
              <div className="row h-100 align-items-center">
                <div className="col-12">
                  <div className="bradcumbContent text-center">
                    <h2>Success!!</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="contact-area section_padding_100">
            <div className="container">
              <div className="row align-items-center p-b-100">
                <div className="col-12 col-md-12">
                  <div className="mosh-about-us-content text-center">
                    <div className="section-heading">
                      <h2>
                        Thanks for reaching out. We'll be in touch shortly.{' '}
                      </h2>
                    </div>
                    <Link to="/contact" className="btn mosh-btn mosh-btn-2">
                      Back
                    </Link>
                    <Link to="/" className="btn mosh-btn">
                      Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default ContactThanks
